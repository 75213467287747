import React from "react"
import { Link } from "gatsby"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

export default ({ data }) => (
  <Layout>
    <SEO title="Home" />
    <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>     
      <Img fluid={data.imageOne.childImageSharp.fluid} className="img-thumbnail float-start"/>
      <Img fluid={data.imageTwo.childImageSharp.fluid} className="img-thumbnail float-start"/>
      <Img fluid={data.imageThree.childImageSharp.fluid} className="img-thumbnail float-start"/>
    
    <h4 className='underline-title'>Publications</h4>
    <p>
    <Link to={data.allBooksJson.nodes[0].link}>{data.allBooksJson.nodes[0].book}</Link> - 1977<br />
    <Link to={data.allBooksJson.nodes[1].link}>{data.allBooksJson.nodes[1].book}</Link> - 1978<br />
    <Link to={data.allBooksJson.nodes[2].link}>{data.allBooksJson.nodes[2].book}</Link> - 1988<br />
    </p>
    </div>
  </Layout>
)

export const query = graphql`
  query {
    allBooksJson {
      nodes {
        book
        pages
        link
        pageInfo {
          node {
            link
            poemTitle
          }
        }
      }
    }
    imageOne: file(relativePath: { eq: "nate1.jpg" }) {
      ...fluidImage
    }
    imageTwo: file(relativePath: { eq: "nate2.jpg" }) {
      ...fluidImage
    }
    imageThree: file(relativePath: { eq: "nate3.jpg" }) {
      ...fluidImage
    }
  }
`
export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 1000) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;

